import React from 'react'

import Layout from '@/components/Layout'
import Seo from '@/components/Seo'

export default () => (
  <Layout>
    <Seo title='Смотря на код с закрытыми глазами' />
    <article>
      <h1>Смотря на код с закрытыми глазами</h1>
      <spoiler title="От переводчика">
        <p>Эта статья&nbsp;&mdash; перевод <a
          href="http://blog.freecodecamp.com/2015/01/a-vision-of-coding-without-opening-your-eyes.html">блогозаписи
          Флориана Бейджерса</a>, слепого программиста из&nbsp;Нидерландов.</p>
        <p>Перевод любительский, поэтому прошу указывать на&nbsp;ошибки и&nbsp;критиковать.</p>
        <p><s>Я&nbsp;не&nbsp;нашёл корректного перевода слова accessibility, поэтому перевёл его как «доступность».
          Если есть вариант получше&nbsp;&mdash; предложите, пожалуйста.</s></p>
        <p><s>Антон Туманов предложил «адаптированность», за что ему большое спасибо.</s></p>
        <p>Как <a href="http://habrahabr.ru/post/260463/#comment_8464767">заметил в комментарии</a> @pepelsbey,
          «доступность» является корректным переводом, вернул её обратно.</p>
        <p>Почему я сделал перевод? Я наткнулся на опрос на Quora.com «<a
          href="http://www.quora.com/How-does-a-visually-impaired-computer-programmer-do-programming">Как
          программируют слабовидящие программисты?</a>». Ответы людей меня впечатлили, а&nbsp;блогозапись Флориана
          показалась наилучшим вариантом для введения в&nbsp;этот важный вопрос.</p>
      </spoiler>
      <p>Я&nbsp;&mdash; программист. А&nbsp;ещё я&nbsp;слеп. Слеп, как крот, как говорится. Таков я&nbsp;от&nbsp;рождения.</p>

      <p>Когда я&nbsp;говорю об&nbsp;этом своим товарищам, то&nbsp;те&nbsp;из&nbsp;них, у&nbsp;кого никогда не&nbsp;было
        проблем со&nbsp;зрением, обычно задают один из&nbsp;трёх вопросов:</p>

      <ul>
        <li><i>Как&nbsp;же ты&nbsp;тогда можешь читать то, что я&nbsp;пишу?</i></li>
        <li><i>Ого. А&nbsp;как ты&nbsp;тогда можешь кодить?</i></li>
        <li>Или&nbsp;&mdash; самый популярный вопрос&nbsp;&mdash; <i>А&nbsp;ты&nbsp;видишь сны?</i></li>
      </ul>
      <p>Мне задают эти вопросы снова и&nbsp;снова. Поэтому в&nbsp;этой блогозаписи я&nbsp;хочу на&nbsp;них ответить.
        Я&nbsp;попытаюсь нарисовать картинку для тех из&nbsp;вас, кто интересуется доступностью и&nbsp;тем, как
        слепые люди пользуются компьютером, кодят и&nbsp;выполняют другие работы в&nbsp;21 веке.</p>
      <figure>
        <p><a href="https://habrastorage.org/files/8ce/4b8/b21/8ce4b8b2150144c783843c4ebe42ecd3.jpg"><img
          src="https://habrastorage.org/files/7c9/851/0ee/7c98510eef314a7ab983bed913d98cc9.jpg"
          alt="Фото Флориана Бэйджэрса" title="Фото Флориана Бэйджэрса. Оригинал — по клику."/></a></p>
        <figcaption>Это я: Флориан Бэйджэрс, или Zersiax, как меня знают в&nbsp;кодерских кругах. Мне сказали что я&nbsp;здесь
          хорошо получился.
        </figcaption>
      </figure>

      <h2>Как ты&nbsp;можешь читать то, что я&nbsp;написал?</h2>
      <p>Я&nbsp;люблю этот вопрос, потому что он&nbsp;позволяет мне сразу&nbsp;же объяснить, как слепые люди
        пользуются компьютером.</p>

      <p>У&nbsp;многих людей сложилось впечатление, что слепые могут пользоваться только особыми, специально
        адаптированными компьютерами. Так думают даже некоторые из&nbsp;моих знакомых, у&nbsp;которых есть проблемы
        со&nbsp;зрением.</p>

      <p>Позвольте мне развеять это заблуждение прямо здесь и&nbsp;сейчас. Сейчас я&nbsp;печатаю этот текст на&nbsp;обычном
        ноутбуке Dell Inspirion 15r SE, который можно купить в&nbsp;любом компьютерном магазине, продающем не&nbsp;самые
        новые ноутбуки. На&nbsp;нём запущена Windows 8 (я её не&nbsp;выбирал, но&nbsp;заменять её слишком
        проблематично
        <nobr>из-за</nobr>
        UEFI). Для подготовки его к&nbsp;использованию я&nbsp;всего лишь установил туда <a href="www.nvaccess.org">NVDA</a>&nbsp;&mdash;
        экранный диктор с&nbsp;открытым исходным кодом.
      </p>

      <p>Экранный диктор обычно просто читает вслух то, что на&nbsp;экране. Он&nbsp;сообщает вам текстовое содержание
        экрана синтезированным (похожим на&nbsp;Siri) голосом. Экранный диктор также позволяет работать с&nbsp;дисплеем
        Брайля&nbsp;&mdash; устройством вывода для чтения на&nbsp;ощупь.</p>
      <figure>
        <img src="https://habrastorage.org/files/3f2/49d/a62/3f249da62ad7488d8d3e5b37ccc13778.jpg"
             title="Кадр из фильма «Тихушники»" alt="Кадр из фильма «Тихушники»"/>
          <figcaption>Дэвид Стрэтэйрн играет слепого хакера и&nbsp;телефонного фрика Эрвина Эмори по&nbsp;прозвищу
            Whistler в&nbsp;триллере 1992 года &laquo;Тихушники&raquo;. Его персонаж пользуется компьютером с&nbsp;помощью
            дисплея Брайля.
          </figcaption>
      </figure>

      <p>Это&nbsp;&mdash; вся адаптация, которая нужна слепому для пользования компьютером. Я могу делать многое из&nbsp;того,
        что вы, скорее всего, считаете невозможным для&nbsp;слепого человека. Например:</p>

      <ul>
        <li>Лазить по&nbsp;вебу с&nbsp;помощью Firefox.</li>
        <li>Писать отчёты в&nbsp;Microsoft Word и форматировать их&nbsp;согласно требованиям колледжских
          преподавателей к&nbsp;оформлению текстов.
        </li>
        <li>Писать потрясающие блогозаписи вроде этой.</li>
        <li>Записывать, править, микшировать и&nbsp;публиковать аудио (моё хобби&nbsp;&mdash; пение и&nbsp;запись
          музыки).
        </li>
        <li>Пользоваться программами для работы со&nbsp;звуком вроде Reaper, Goldwave, Audacity и&nbsp;Sonar.</li>
        <li>Разрабатывать сайты, используя Eclipse, Visual Studio (ироничное название) и&nbsp;старый добрый
          Notepad++.
        </li>
      </ul>
      <p>Я&nbsp;перечисляю все эти распространённые технологии чтобы показать: я&nbsp;могу ими пользоваться точно так&nbsp;же,
        как и&nbsp;люди, у&nbsp;которых нет проблем со&nbsp;зрением.</p>

      <p>Если вы&nbsp;будете писать очередное масштабное приложение со&nbsp;сногсшибательным интерфейсом и&nbsp;великолепным
        рабочим процессом, я&nbsp;скромно прошу вас убедиться в&nbsp;том, что его доступность включена в&nbsp;план.
        В&nbsp;наши дни нет никакой причины не&nbsp;пользоваться инструментарием доступности интерфейса. Это намного
        проще, чем вы&nbsp;думаете. Да, сюда входят Android Activities, iOS NsViews и&nbsp;<nobr>HTML5-виджеты
        </nobr>
        , о&nbsp;которых вы, возможно, подумали.
      </p>

      <p>Я&nbsp;вступил во&nbsp;Free Code Camp несколько недель назад и&nbsp;мне там очень понравилось. Последние
        несколько лет я&nbsp;учился по&nbsp;специальности &laquo;Информатика&raquo; и&nbsp;провалил семестр,
        включавший много работы со&nbsp;стеком MEAN. И&nbsp;я&nbsp;очень рад, что нашёл такое замечательное
        сообщество, в&nbsp;котором можно состоять и&nbsp;учиться. Уверен&nbsp;&mdash; в&nbsp;этот раз я&nbsp;с&nbsp;честью
        закончу этот семестр.</p>

      <p>К&nbsp;сожалению, я&nbsp;столкнулся с&nbsp;проблемой доступности, когда работал по&nbsp;известным <a
        href="http://dash.generalassemb.ly">обучающим материалам по&nbsp;Dash</a> от&nbsp;General Assembly. Эти
        материалы, несомненно, хороши, но&nbsp;для&nbsp;меня они были совершенно нечитаемы, так как авторы решили
        вставить все тексты картинками. Текстовое описание картинок, с&nbsp;которым могли&nbsp;бы работать экранные
        дикторы, было минимально. К&nbsp;сожалению, экранные дикторы могут читать только текстовые описания. Они не&nbsp;столь
        умны, чтобы распознавать графику.</p>

      <p>К&nbsp;счастью, несколько товарищей на&nbsp;Free Code Camp посочувствовали моему положению и&nbsp;добровольно
        транскрибировали для меня все слайды. Эти люди меня &laquo;приятно поразили&raquo;, как выражаются наши
        дорогие западные соседи за&nbsp;морем. Я&nbsp;очень благодарен за&nbsp;работу, которую они проделали для
        моего обучения. Вы&nbsp;знаете, о&nbsp;ком я&nbsp;говорю. Большое вам спасибо!</p>

      <h2>Но&hellip; как ты&nbsp;пишешь код?</h2>
      <blockquote>If&nbsp;открывающая скобка x&nbsp;равно пяти закрывающая скобка открывающая фигурная скобка print
        открывающая скобка кавычка hello world восклицательный знак кавычка закрывающая скобка закрывающая фигурная
        скобка.
      </blockquote>

      <p>Это то, как я&nbsp;слышу типичный блок &laquo;Если&raquo; в&nbsp;похожем на&nbsp;Java языке программирования.
        Довольно подробно, как видите. Я&nbsp;стараюсь отключать упоминания скобок, если мне не&nbsp;нужно
        специально проверять их&nbsp;расстановку при ловле ошибок, так что я не&nbsp;схожу с&nbsp;ума от&nbsp;довольно
        многословных описаний знаков препинания. Другие решают эту проблему заменой &laquo;открывающей скобки&raquo;
        на&nbsp;<nobr>что-то</nobr>
        вроде &laquo;оскоб&raquo; или &laquo;начало&raquo;, просто чтобы сохранить несколько миллисекунд. Скорость
        озвучки текста очень высока для&nbsp;непривычных к&nbsp;этому людей.
      </p>

      <p><a href="https://soundcloud.com/freecodecamp/zersiaxs-screen-reader">Ссылка на&nbsp;звукозапись «Zersiax's
        Screen Reader»</a></p>

      <p>Для тех из&nbsp;вас, кто ничего не&nbsp;понял: это то, как мой экранный диктор читает первую часть этой
        блогозаписи, которую я&nbsp;пишу в&nbsp;NotePad++.</p>

      <p>Так что то, как я&nbsp;пишу код, не&nbsp;сильно отличается от&nbsp;того, как пишут код другие. Я&nbsp;научился
        слепой печати и&nbsp;мысленно представляю себе код, так что могу работать с&nbsp;ним точно так&nbsp;же, как
        и&nbsp;вы. Единственное отличие в&nbsp;том, что я&nbsp;почти не&nbsp;использую мышь. Вместо неё я&nbsp;стараюсь
        пользоваться горячими клавишами и&nbsp;командной строкой.</p>

      <p>К&nbsp;сожалению, в&nbsp;этой области всё ещё не&nbsp;совсем хорошо. Ведущие инструменты, которыми пользуются
        кодеры, такие, как редактор IntelliJ, а&nbsp;также его подвиды (PHPStorm, WebStorm, PyCharm) совершенно не&nbsp;доступны
        просто
        <nobr>из-за</nobr>
        того, что их&nbsp;разработчики не&nbsp;придерживались руководств по&nbsp;соблюдению доступности. Они не&nbsp;подготовили
        для экранных дикторов текстовые пометки или описания, с&nbsp;которыми те&nbsp;могли&nbsp;бы работать. Это&nbsp;же
        касается приложений вроде SourceTree, которое хоть постепенно и&nbsp;становится лучше, но&nbsp;использовать
        его всё ещё очень неудобно.
      </p>

      <p>Поэтому мне приходится искать доступные учебные материалы, программы и&nbsp;инструменты, а&nbsp;не&nbsp;просто
        брать и&nbsp;использовать подходящую среду разработки.</p>

      <h2>Как ты&nbsp;видишь сны?</h2>
      <p>Я&nbsp;обещал ответить на&nbsp;все три вопроса, поэтому отвечу и&nbsp;на&nbsp;этот. Но&nbsp;не&nbsp;ожидайте
        <nobr>чего-то</nobr>
        из&nbsp;ряда вон выходящего.
      </p>

      <p>Я&nbsp;вижу сны так&nbsp;же, как и&nbsp;вы. Мой мозг транслирует опыт и&nbsp;события, произошедшие со&nbsp;мной
        за&nbsp;день, в&nbsp;сны, которые я&nbsp;вижу ночью. Разница в&nbsp;том, что я&nbsp;ничего в&nbsp;них не&nbsp;вижу.</p>

      <p>Вместо этого я&nbsp;слышу, обоняю и&nbsp;осязаю всё, как и&nbsp;в&nbsp;жизни. Причина проста: сны
        основываются на&nbsp;ранее полученных визуальных образах, создавая из&nbsp;них визуальный ряд сна. Так как я&nbsp;слеп
        с&nbsp;рождения, то&nbsp;в&nbsp;моей памяти нет визуальных образов, на&nbsp;которых можно основываться.
        Визуальная состоявляющая моих снов получает большую жирную 404&nbsp;ошибку: изображение не&nbsp;найдено.</p>

      <h2>Покодить со&nbsp;мной</h2>
      <p>Добровольцы с&nbsp;Free Code Camp попросили меня написать эту блогозапись, чтобы поделиться со&nbsp;всем
        миром моим способом работы. После того радушного приёма, который я&nbsp;получил в&nbsp;этом сообщества, я&nbsp;был
        только рад её написать. Я&nbsp;действительно надеюсь что вы&nbsp;извлечёте из&nbsp;этого пользу.</p>

      <p>Я&nbsp;могу говорить об&nbsp;этом часами и&nbsp;эта статья уже переросла размер, который я&nbsp;изначально
        запланировал. Если у&nbsp;вас есть вопросы, найдите меня в&nbsp;<nobr>чат-комнатах</nobr>
        Free Code Camp. Там я&nbsp;под ником Zersiax, под ним&nbsp;же я&nbsp;в&nbsp;Твиттере. Спасибо за&nbsp;чтение.
        Увидимся! (Извините, не&nbsp;смог удержаться и&nbsp;не&nbsp;сказать этого) :)
      </p>
    </article>
  </Layout>
)
